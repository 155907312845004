<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('company.label', 2)" />
      <button class="small" @click="$store.dispatch('modals/openModal', { modal: 'editCompany', entity: { name: '' } })">
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editCompany', entity: $event })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteCompany', entity: $event })"
    />

    <edit-company-modal @update="updated" />
    <delete-modal
      type="deleteCompany"
      entity-translation-key="company"
      label="name"
      @update="loadEntities"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import Modal from '@/components/utils/Modal.vue';
import EditCompanyModal from '@/components/entities/company/EditCompanyModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'CompanyIndexPage',
  components: {
    DeleteModal,
    EditCompanyModal,
    Modal,
    EntityTable,
  },
  setup() {
    const { company: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/companies', { sort: 'name' });

    const store = useStore();
    const updated = () => {
      loadEntities();
      store.dispatch('auth/FETCH_USER');
    };

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      updated,
    };
  },
};
</script>
