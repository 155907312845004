<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editCompany"
    :options="{
      properties: {
        name: '',
        adminColorMain: '',
        adminColorLight: '',
        adminColorDark: '',
        adminColorHighlight: '',
      },
      label: 'Firma',
      postUri: '/companies',
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-input
      v-model="properties.adminColorMain"
      :label="$t('general.field.adminColorMain')"
      type="color"
      :error="errors.adminColorMain"
      required
    />
    <fancy-input
      v-model="properties.adminColorLight"
      :label="$t('general.field.adminColorLight')"
      type="color"
      :error="errors.adminColorLight"
      required
    />
    <fancy-input
      v-model="properties.adminColorDark"
      :label="$t('general.field.adminColorDark')"
      type="color"
      :error="errors.adminColorDark"
      required
    />
    <fancy-input
      v-model="properties.adminColorHighlight"
      :label="$t('general.field.adminColorHighlight')"
      type="color"
      :error="errors.adminColorHighlight"
      required
    />
  </modal-form>
</template>
<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import FormButtons from '@/components/utils/forms/FormButtons.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';

export default {
  components: { FancyInput, FormButtons, ModalForm },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  emits: ['update'],
};
</script>
